.bottom-bar{
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
}

.bottom-bar-content div a{
    text-decoration: none;
    color:rgb(123, 123, 123);
}

.wldy-logo{
    display:flex;
    height:60%;
    margin-top: 10px;
}
.bottom-bar-logo{
    height: 100px;
    width: 100px;
}

.bottom-bar-content{
    display:flex;
    justify-content:space-evenly;
    flex-wrap: nowrap;
    align-items: flex-start;
}
.bottom-bar-content div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
    margin-top: 10px;
}

.bottom-bar-content div div{
    margin: 5px 5px 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.bottom-bar-content div div a{
    display: block;
    width: fit-content;
}
.bottom-links{
    text-decoration: none;
    background:inherit;
}

.bottombar-text{
    font-size: 50px;
    background-color: red;
    background-image: linear-gradient(90deg, #b839eb, #d18cf2);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

/* @media screen and (max-width: 960px) {
    .bottom-bar-content{
        display:f;
        justify-content:space-evenly;
    }
} */