* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.myclan{
  display: flex;
  color: white;
  justify-content: center;
  margin-top: 30px;
}

.listingbutton {
  padding: 15px 25px 15px 25px;
  background-color: #D235BE;
  border: none;
  color: #fff;
  border-radius: 20px;
  font-size: 1em;
  line-height: 1.2em;
  display: block;
  text-align: center;
  cursor:pointer;
}

.genric-button {
  margin-top: 40px;
  padding: 15px 25px 15px 25px;
  background-color: #D235BE;
  border: none;
  color: #fff;
  border-radius: 20px;
  font-size: 1em;
  line-height: 1.2em;
  display: block;
  text-align: center;
  cursor:pointer;
}

._loading_overlay_overlay{
  border-radius: 20px;
}

.connect-button {
  position: relative;
  padding: 8px 20px;
  border-radius: 18px;
  width: 124px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  transition:all 1s;
  background: linear-gradient(90deg, rgba(73,4,87,1) 0%, rgba(196,0,255,1) 100%);
}

.account-info{
  display: grid;
  color:#FFAC33;
  font-size:20px;
  justify-content:center;
  margin-top:10px;
  border-radius: 12px;
  border-color: rgb(165, 25, 144);
  border-width: 1px;
  border-style: solid;
}

.account-info-header{
  display: flex;
  justify-content:center;
  color:rgb(187, 50, 169);
}

.myclan-header{
  margin-top: 40px;
  display: flex;
  justify-content:center;
  color:rgb(187, 50, 169);
}

.chain-id{
  display: flex;
  justify-content:center;
}

.home,
.services,
.products,
.partners,
.sign-up,
.marketing,
.consulting {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.partners {
  background: #35105b;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.marketing {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.consulting {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}



