.dropdown-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    width: 135px;
  }

  .dropdown-links:hover {
    background-color: #1888ff;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  .dropdown-item {
    display: grid;
    align-items: center;
  }
  

  .dropdown-item button{
    background: #D235BE;
    border-radius: 4px;
  }
