.modalContainer .createorder-body {
  flex: 50%;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.order-price input {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  vertical-align: middle;
}

.order-price label {
  vertical-align: middle;
}