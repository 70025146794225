
.kick-modalContainer {
    width: 500px;
    height: 500px;
    border-radius: 12px;
    color: #fff;
    background-color: rgb(65, 70, 170);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  
  .kick-modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  
  .kick-modalContainer .body {
    display: block;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1rem;
  }

  .kick-item{
    display: flex;
    justify-content: space-between;
  }
  .kick-item p {
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 50px;
    align-self: center;
  }
  .kick-item button{
    margin-top: 50px;
    margin-right: 20px;
    padding: 15px 25px 15px 25px;
    background-color: #D235BE;
    border: none;
    color: #fff;
    border-radius: 20px;
    font-size: 1em;
    line-height: 1.2em;
    display: block;
    text-align: center;
    cursor:pointer;
  }
  

  
  