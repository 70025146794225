.play-btn {
  position: relative;
  padding: 8px 20px;
  border-radius: 18px;
  width: 124px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  transition:all 1s;
  background: linear-gradient(90deg, rgba(73,4,87,1) 0%, rgba(196,0,255,1) 100%);
}
.play-btn::before {
  border-radius: 18px;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(8,112,0,1) 0%, rgba(220,238,211,1) 100%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}
.play-btn:hover::before {
  border-radius: 18px;
  opacity: 1;
}
.connect-button-container{
  display: flex;
  justify-content: center;
}
.user-balance {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border-style: solid;
  border-color:rgb(165, 25, 144);
  padding: 5px;
  margin-right: 10px;
}

.user-balance img {
  height: 25px;
  width: 25px;
}


.user-balance label {
  color: white;
  margin-left: 10px;
}