

.highscore-title{
    color:rgb(185, 57, 199);
    text-align: center;
    margin-top: 25px;
    font-size:50px;
    width: 100%;
}
.highscoreres-table {
    color:#fff;
    width: 100%;
    text-align: left;
    margin-top: 20px;
}

.highscore-component{
    /* display: flex; */
    width: 100%;
}

.highscoreres-table table{
    width: 100%;
    min-width: 500px;
    border-radius: 12px;
    border-color: rgb(165, 25, 144);
    border-width: 1px;
    border-style: solid;
}

.highscoreres-table th {
    padding: 10px 25px;
    cursor: pointer;
}
.no-sort{
    cursor: not-allowed !important;
}
.highscoreres-table td {
    padding: 10px 25px;
    /* max-width: 200px; */
    text-overflow: ellipsis;
    overflow: hidden;
}

.score-bottom-panel{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    /* margin-left: 80px; */
    justify-content: space-around;
}

.next-button{
    width: fit-content;
    padding: 5px 25px 5px 25px;
    font-size: 1.4em;
}

.previous-button{
    width: fit-content;
    padding: 5px 25px 5px 25px;
    font-size: 1.4em;
    
}

.go-to-page-button{
    width: fit-content;
    padding: 5px 25px 5px 25px;
    font-size: 1.4em;
}

.input-page-number{
    margin-left: 50px;
    width: 50px;
    height:40px;
    border-radius: 10px;
    text-align: center;
}

.score-bottom-panel button{
    background: rgb(150, 60, 161);
    height:40px;
    border-radius: 10px;
    color:white;
}
.highscore-filter button{
    background: rgb(150, 60, 161);
    height:40px;
    border-radius: 10px;
    color:white;
}
.highscore-filter{
    display: flex;
    margin-bottom: 25px;
}

.category{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* align-content: flex-start; */
    width:120px;
}
.category h4 {
    font-size: 24px;
    font-weight: 500;
}

.category-options{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 10px;
}


.category-options div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 10px;
    font-size: 20px;
}

.category-options input{
    margin: 0 10px 0 15px
}

.sortby{
    width:100px;
}
.sortby button{
    width:100px;
}
.sortby-options{
    display: none;
}

.sortby:hover .sortby-options {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 960px) {
    .score-bottom-panel{
        display:grid;
    }
    .next-button{
        margin-left: 0px;
    }
     
    .go-to-page-button{
        margin-left: 0px;
    }
    
    .input-page-number{
        margin-left: 0px;
    }

}
