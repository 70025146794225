.marketplace-container{
    display: flex;
    color: white;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 100px;
    margin-bottom: 100px;
    justify-content: space-evenly;
}

.item {
    color:white;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
    border-color:#670870;
    border-style: solid;
    border-radius: 25px;
}

.item button{
    width: 80%;
    display: block;
    padding: 5px 50px 5px 50px;    
    background: linear-gradient(90deg, rgb(73, 4, 87) 0%, rgb(196, 0, 255) 100%);
    border: none;
    color: #fff;
    border-radius: 20px;
    font-size: 1.6em;
    font-weight: 900;
    line-height: 1.2em;
    display: block;
    text-align: center;
    cursor:pointer;
    margin-top: 20px;
}

.item-details{
    margin-top:10px;
    width: 80%;
}

.item img{
    height: 300px;
    width: 300px;
    background: radial-gradient(at 50% 50%,#670870,#0c0a2b);
    border-radius: 25px;
}

.raritytext {

    border-radius: 10px;
    padding:5px 20px 5px 20px;
    margin-right: 20%;
    margin-left: 20%;
}
.uncommon{
    background: #ED6D4F;
}
.common{
    background: rgba(255, 255, 255, 0.5)
}

.epic{
    background: #3D85E6;
}

.legendary{
    background: #842DDA;
}
.outofstock{
    background: rgba(255, 255, 255, 0.5)!important;
    font-size: 1.2em!important;
    pointer-events:none;
}
.rare{
    background: #36CF75;
}
.mythic{
    background: #FF63E1;
}

.slide.active.market img{
    height : 500px;
    width: 100%;
    margin: 0 50px 0 50px;
    border-width: 0px;
}

.slide.active.slider-item-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.itemtitle {
    margin-top: 10px;
    font-weight: 500;
    font-size: 1.4em;
}
.item-details .itemcategory {
    font-size: 0.8em;
}
.item-details .pricetext {
    color: #31ff3b !important;;
    margin-top: 10px !important;
    font-size: 1.5em !important;
    font-weight:900 !important;
}
.item-details .remainingtext {
    font-style: italic;
    font-size: 0.8em;
    font-weight:900;
}

/* .slide.active img {
    width: 200px;
    height: 200px;
} */

/* .slider-item button{
    padding: 15px 25px 15px 25px;
    background-color: #D235BE;
    border: none;
    color: #fff;
    border-radius: 20px;
    font-size: 1em;
    line-height: 1.2em;
    display: block;
    text-align: center;
    cursor:pointer;
    margin-top: 20px;
    
} */
.item-slider {
    position: relative;
    margin-top: 50px;
    padding: 40px 0 20px 0;
    background: radial-gradient(at 50% 50%,#670870,#0c0a2b,#0c0a2b);
}