
.nomics-ticker-widget-embedded {
    width: 100%!important;
    padding-top:50px;
    padding-bottom:50px;
    /* min-width: 400px!important; */
  }
.nomics-container {
    width:100%;
    padding-top:50px;
    padding-bottom:50px;
    /* background:#707070; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: 'Finlandica', sans-serif;
    flex-wrap: wrap;
    background: radial-gradient(at 65% 400px,#340639,#0c0a2b,#0c0a2b);
    
}
.nomics-ticker-widget-embedded.nomics-ticker-widget-bg-light {
    background-color: rgba(255,255,255,0.25)!important;
}

.nomics-ticker-widget-footer{
    display: block !important; 
    visibility: visible !important; 
    opacity: 1 !important;
}

.topbg1 .info{
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    /* flex-wrap: wrap-reverse; */
    /* flex-wrap: wrap; */
  
  }

  
.topbg1 {
    /* background-image: url("/images/website_bg.jpg"); */
    /* height: 100vh; */
    padding: 0 0 25px 0;
  /* 
    background-color: #200B6E;
    background-position: center; 
    background-repeat: no-repeat no-repeat; 
    background-size: cover;  */
  
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  
  }

.topbg1 .info .socials{
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    width: 60%;

}

.topbg1 .socials .socialimg {
    height: 80px;
    margin: 0 10px 0 10px;
}

.topbg1 .topimgs{
    min-width: 450px;
    max-width: 45%;
    }

.wizardimg1 {
    /* height: 750px; */
    width: 100%;
    float: right;
    margin-right: 0px;
    background: radial-gradient(#da2be9 0%,#0c0a2b 70%);
    /* margin-top: 150px; */
    /* width: 100%; */
    /* margin-top: 100px; */
}

    
.wizardimg2 {
    height: 500px;
    float: right;
    margin-right: 0px;
    margin-bottom: -1000px;
    margin-top: 150px;
    /* width: 100%; */
    /* margin-top: 100px; */
}

.button1 {
    width: fit-content;
    padding: 15px 50px 15px 50px;
    background-color: #f72585;
    border-radius: 32px;
    /* margin-top: 40px; */
    color:#fff;
    font-size: 2em;
    text-decoration: none;
    font-family: 'Finlandica', sans-serif;
    margin-top: 30px;
}

.topbg1 h1 {
    color: #fff;

    margin-top: 25px;
    font-size: 2em;
    margin-bottom: 20px;
    font-weight: 600;
}

.topbg1 h2 {
    color: #f72585;
    font-size: 4em;
    margin-top: 100px;
    margin-bottom: 10px;
    font-weight: 700;
    text-align: center;
}


.topleft{
    background: radial-gradient(at 37% 290px,#670870,#0c0a2b,#0c0a2b);
    padding: 0 0 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media only screen and (max-width: 800px) {
    .topbg1 .topimgs{
        /* flex-wrap: wrap; */
        display: none;

    }
    .topbg1 .socials .socialimg {
        height: 40px!important;
        }

        .navbar{
        display: none!important;
        }

        .topbg1 .info .topleft{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        /* flex-wrap: wrap; */
        
        }

    .topbg1 h2 {
        text-align: center;
        font-size: 3em!important;
        margin-top: 25px!important;
    }

    .topbg1 h1 {
        text-align: center;
        font-size: 2em!important;
        margin-bottom: 25px!important;
    }

    .topbg1 {
        padding: 20px 10px 20px 10px;
        width: 100%;
        margin-bottom: 50px;
    }


    .topbg1 .info .socials{
    margin-top: 25px!important;
    display: flex;
    justify-content: space-around;
    }
}

.slider {
    position: relative;
    margin-top:50px;
    padding: 40px 0 20px 0;
    background: radial-gradient(at 60% 290px,#670870,#0c0a2b,#0c0a2b);
}
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: rgb(217, 107, 232);
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: rgb(217, 107, 232);
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .slide.active {
    display: flex;
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
    justify-content: center;
  }

  .slide.active div{
    max-width: 800px;
    display: flex;
  
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .homeslider h1 {
    color: #fff;
    font-weight: 600;
    font-size: 3.5em;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-family: 'Finlandica', sans-serif;
  }
  
  .homeslider p {
    color: #F6D155;
    font-weight: 500;
    font-size: 2em;
    line-height: 1.5em;
    margin-top: 25px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Finlandica', sans-serif;
  }
  
  .homeslider img{
    height : 450px;
    min-height: 200px;
    margin: 0 50px 0 50px;
    border-radius: 12px;
    border-color: rgb(232, 23, 201);
    /* border-width: 1px;
    border-style: solid; */
  }

.divider-slider{
    margin-top: 50px;
}


.twitter-feed{
    display: flex;
    height: 800px;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: center;
}

.twitter-timeline{
  padding: 0 50px 0 50px;
    height: 800px;
    overflow-y: auto;
    border-radius: 12px;
    border-color: rgb(232, 23, 201);
    border-width: 1px;
    border-style: solid;
}
.twitter-timeline::-webkit-scrollbar {
    width: 20px;
}
  
  /* Track */
  .twitter-timeline::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .twitter-timeline::-webkit-scrollbar-thumb {
    background: rgb(153, 25, 147); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .twitter-timeline::-webkit-scrollbar-thumb:hover {
    background: #5a0a68; 
  }