
.menu-container button{
    padding: 15px 25px 15px 25px;
    background-color: #D235BE;
    border: none;
    color: #fff;
    border-radius: 20px;
    font-size: 1em;
    line-height: 1.2em;
    display: block;
    text-align: center;
    cursor:pointer;
    margin-top: 20px;
}

label{
    color: white;
}

.marketplace-container{
    display: flex;
    color: white;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 100px;
    margin-bottom: 100px;
    justify-content: space-evenly;
}



.item {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
    border-color:#670870;
    border-style: solid;
    border-radius: 25px;
}

.react-date-picker{
    background:white;
}


.item button{
    min-width: 80%;
    display: block;
    padding: 5px 50px 5px 50px;    
    background: linear-gradient(90deg, rgb(73, 4, 87) 0%, rgb(196, 0, 255) 100%);
    border: none;
    color: #fff;
    border-radius: 20px;
    font-size: 1.6em;
    font-weight: 900;
    line-height: 1.2em;
    display: block;
    text-align: center;
    cursor:pointer;
    margin-top: 20px;
}

.cancle-order{
    background: rgba(255, 0, 0, 0.5)!important;
}

.item-details{
    margin-top:10px;
}

.item img{
    height: 300px;
    width: 300px;
    background: radial-gradient(at 50% 50%,#670870,#0c0a2b);
    border-radius: 25px;
}

.raritytext {

    border-radius: 10px;
    padding:5px 20px 5px 20px;
    margin-right: 20%;
    margin-left: 20%;
}
.uncommon{
    background: #ED6D4F;
}
.common{
    background: rgba(255, 255, 255, 0.5)
}


.epic{
    background: #3D85E6;
}

.legendary{
    background: #842DDA;
}
.outofstock{
    background: rgba(255, 255, 255, 0.5)!important;
    pointer-events:none;
}
.rare{
    background: #36CF75;
}
.mythic{
    background: #FF63E1;
}



.score-bottom-panel1{
    display: flex;
    margin-top: 50px;
    margin-left: 80px;
    justify-content: space-between;
}

.next-button{
    width: 80px;
    margin-left: 50px;
}

.previous-button{
    width: 80px;
}

.go-to-page-button{
    margin-left: 5px;
    width: 100px;
}

.input-page-number{
    margin-left: 50px;
    width: 50px;
    height:40px;
    border-radius: 10px;
    text-align: center;
}

.score-bottom-panel1 button{
    background: rgb(150, 60, 161);
    height:40px;
    border-radius: 10px;
    color:white;
}