.hide {
    display: none!important;
}
.pagedesc{
    text-align: justify;
    width: 50%;
    font-size: 1.5em;
    line-height: 2em;
    margin-right:40px;
}
.pagedesccontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
h1{
    text-align: center;
    font-weight: 500;
    font-size: 3.5em;
    margin-bottom: 10px;
}
h1,h2,.pagedesc{
    color:#fff;
}
.buttonwrapper_referral{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    margin-bottom: 60px;
}
.twitter-connect{
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    justify-content:space-around;
}
.twitter-connect{
    margin-top: 10px;
    margin-bottom: 10px;
}
.text-container h1{
    color: rgb(187, 50, 169);
    margin: 5px;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}
.item-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}
button {
    font-weight: 500;
    font-size: 2em;
}
.text-container a {
    font-size: 18px;
    color: #FFAC33;
}
  
.text-container button {
    margin: 5px;
    padding: 10px 20px;
    background-color: rgb(102, 102, 236);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in;
}
.rewardscontainer{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
  
.text-container button:hover {
    background-color: darkblue;
}
  
.text-container button.copied {
    background-color: green;
}
.copybtn {
    width: 250px;
    font-size: 1em;
}
.referral-link{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 12px;
    border-color: rgb(165, 25, 144);
    border-width: 1px;
    border-style: solid;
    margin-bottom:50px;
}

.register-twitter{
    padding-left: 15px;
    display: flex;
    width: 300px;
    height: 50px;
    background-color: #49a1eb;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    /* margin-top:50px; */
    margin-left: 20px;

}
.register-twitter p{
    font-size: 22px;
    color:white;
}

.register-twitter hr{
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
    border-left: 2px solid rgb(66, 74, 66, 0.1)
}
.referral-container{
    display: flex;
    margin-top: 30px;
    margin-bottom: 150px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.loadcontent-container{
    width: 100%;
    margin-top:40px;
}

.disabled{
    background: rgba(255, 255, 255, 0.5)!important;
    pointer-events:none;
}