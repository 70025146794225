.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: #0c0a2b;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
