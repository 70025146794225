.team {
    margin-top:10px;
}
.team h2 {
color: #f72585;
font-size: 4em;
margin-top: 60px;
margin-bottom: 10px;
text-align: center;
}


.team .teamcontainer {

    display: flex;
    justify-content: space-around;
    margin: 30px 0 80px 0;
    padding: 0 10% 0 10%;
    flex-flow:wrap
  }
  
  .team .teamcontainer .teammember{
  
    /* width: 15%; */
    margin: 30px 30px 30px 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  
  
  .team .teamcontainer .teammember img{
  
    width: 200px;
    border-radius: 100px;
    border: 3px solid #F6D155;
  }
  
  
  .team .teamcontainer .teammember .techmediaimage{
  
    width: 350px;
    border-radius: 15px;
    border: 3px solid #F6D155;
  }
  
  .team .teamcontainer .teammember a{
  
    text-decoration: none;
  }
  
  .team .teamcontainer .teammember .links {
  
    display: flex;
    justify-content: space-around;
    width: 50%;
    text-decoration: none;
  }
  
  
  .team .teamcontainer .teammember .links a{
  
    text-decoration: none;
  }
  
  
  
  .team .teamcontainer .teammember .links img{
    border:none;
  
    width: 30px;
  }

  .team .teamcontainer .teammember h3{

    color: #f72585;
    font-size: 2.2em;
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: center;
  }
  
  
  .team .teamcontainer .teammember h4{
  
    color: #f72585;
    
    font-size: 1.8em;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
  }

  .divider1 {
    height: 2px;
    width: 100%;
    background-color: #f72585;
  }