

.listingheader{
    display: flex;
    color:rgb(185, 57, 199);
    font-size:50px;
    justify-content:center;
    margin-top: 150px;
    margin-bottom: 150px;
}

.listingcontainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}
.mylisting{
  background-color:rgba(198, 214, 255,0.1);
  padding: 20px 5% 20px 5%;
  display: block;
  /* flex-direction: column; */
  width: 300px;
  align-items: center;
  text-align: center;
  text-decoration: none;
  margin: 25px 20px 25px 20px;
  border-radius: 12px;
  border-color: rgb(165, 25, 144);
  border-width: 1px;
  border-style: solid;
  background: radial-gradient(at 50% 200px,#670870,#0c0a2b);
}
  .listing{
    background-color:rgba(198, 214, 255,0.1);
    padding: 20px 5% 20px 5%;
    display: block;
    /* flex-direction: column; */
    height: 400px;
    align-items: center;
    text-align: center;
    text-decoration: none;
    margin: 25px 20px 25px 20px;
    width: 300px;
    border-radius: 12px;
    border-color: rgb(165, 25, 144);
    border-width: 1px;
    border-style: solid;
    background: radial-gradient(at 50% 200px,#670870,#0c0a2b);
  }

  .mylisting div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mylisting div h2{
    margin-bottom: 20px;
    margin-top: 5px;
    font-size: 1.2em;
    color: white
  }


  .listing img{
    margin: 0 0 10px 0;
    width: 200px;
  }
  
  .listing div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .listing div h2{
    margin-bottom: 20px;
    margin-top: 5px;
    font-size: 1.2em;
    color: white
  }


.loading-overlay{
  margin-bottom: 100px;
}
